import Vue from 'vue'
import moment from 'moment'
export default{
  ServerUrl_dev: 'https://evio.anvelopeinstoc.ro/dev/api/',
  ServerUrl_PROD: 'https://evio.anvelopeinstoc.ro/api/',
  ServerUrl: 'https://myautoserv.com/api/index.php/',
  pickerOptions: {
    shortcuts: [
      {
        text: 'Astazi',
        onClick: function (picker) {
          picker.$emit('pick', new Date())
        }
      },
      {
        text: 'Ieri',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24)
          picker.$emit('pick', date)
        }
      },
      {
        text: 'Acum o sapt.',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', date)
        }
      }
    ]
  },
  pickerOptionsFuture: {
    shortcuts: [
      {
        text: 'Astazi',
        onClick: function (picker) {
          picker.$emit('pick', new Date())
        }
      },
      {
        text: 'Maine',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24)
          picker.$emit('pick', date)
        }
      },
      {
        text: 'Poimaine',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 2)
          picker.$emit('pick', date)
        }
      },
      {
        text: 'Peste 3 zile',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 3)
          picker.$emit('pick', date)
        }
      },
      {
        text: 'Peste 5 zile',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 5)
          picker.$emit('pick', date)
        }
      },
      {
        text: 'Peste o sapt.',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 5)
          picker.$emit('pick', date)
        }
      }
    ]
  },
  pickerOptionsDateRange: {
    shortcuts: [
      {
        text: 'Astazi',
        onClick: function (picker) {
          var from = moment(new Date()).format('YYYY-MM-DD 00:00:00')
          var to = moment(new Date()).format('YYYY-MM-DD 23:59:00')
          picker.$emit('pick', [from, to])
        }
      },
      {
        text: 'Ieri',
        onClick: function (picker) {
          var from = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD 00:00:00')
          var to = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD 23:59:00')
          picker.$emit('pick', [from, to])
        }
      },
      {
        text: 'Ultima sapt.',
        onClick: function (picker) {
          const date = new Date()
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [date, new Date()])
        }
      },
      {
        text: 'Luna curenta',
        onClick: function (picker) {
          var date = new Date()
          var y = date.getFullYear()
          var m = date.getMonth()
          var firstDay = new Date(y, m, 1)
          var lastDay = new Date(y, m + 1, 0)
          firstDay = moment(firstDay).format('YYYY-MM-DD HH:kk:ss')
          lastDay = moment(lastDay).format('YYYY-MM-DD HH:kk:ss')
          picker.$emit('pick', [firstDay, lastDay])
        }
      },
      {
        text: 'Luna trecuta',
        onClick: function (picker) {
          var dateFrom = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:kk:ss')
          var dateTo = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:kk:ss')
          picker.$emit('pick', [dateFrom, dateTo])
        }
      },
      {
        text: 'Anul curent',
        onClick: function (picker) {
          var thisYear = (new Date()).getFullYear()
          var start = new Date('1/1/' + thisYear)
          picker.$emit('pick', [start, new Date()])
        }
      },
      {
        text: 'Anul trecut',
        onClick: function (picker) {
          const date = new Date()
          const year = date.getFullYear()
          var lastYearStart = new Date(year - 1, 0, 1, '00', '00', '00')
          var lastYearEnd = new Date(year - 1, 11, 31, '00', '00', '00')
          picker.$emit('pick', [lastYearStart, lastYearEnd])
        }
      },
      {
        text: 'Tot timpul',
        onClick: function (picker) {
          const to = new Date()
          var from = new Date(2000, 0, 1, '00', '00', '00')
          picker.$emit('pick', [from, to])
        }
      }
    ]
  }
}

export const EventBus = new Vue()
