<template>
    <base-page>
        <titlu-pagina Titlu="Devize" @on_add_clicked="show_dialog()" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Data intrare' >
                                <el-date-picker v-model='Filters.DataIntrare' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data inchidere' >
                                <el-date-picker v-model='Filters.DataInchidere' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' filterable>
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='deschis' value='deschis'></el-option>
                                    <el-option label='inchis' value='inchis'></el-option>
                                    <el-option label='anulat' value='anulat'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Masina' >
                                <el-select class='full-width' v-model='Filters.IdMasina' filterable>
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>
                        </el-col>

                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Results" >    
            <el-table-column label='Data intrare'>
                <template slot-scope='scope'>
                    {{ scope.row.DataIntrare | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column label='Data inchidere'>
                <template slot-scope='scope'>
                    {{ scope.row.DataInchidere | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column prop='Total' label='Total'></el-table-column>
            <el-table-column prop='Masina' label='Masina'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-deviz')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Generare factura" v-if="scope.row.Status != 'facturat'">
                        <el-button type="success" icon="el-icon-document" circle @click="genereaza_factura(scope.row)" />
                    </el-tooltip>

                    <el-tooltip content="Vizualizare factura" v-if="scope.row.Status == 'facturat'">
                        <el-button type="primary" icon="el-icon-document" circle @click="preview_factura(scope.row)" />
                    </el-tooltip>

                    <el-tooltip content="Vizualizare produse">
                        <el-button type="info" icon="el-icon-menu" circle @click="preview_produse(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Devize-dialog ref='dlg' @save="refresh_info()" />
        <factura-dlg ref="factura_pdf"/>
        <Deviz-produse-pdf ref="deviz-produse-pdf"/>
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Devize_dialog from '@/pages/devize/Devize_dialog.vue';
import Deviz_produse_pdf from '@/pages/devize/Deviz_produse_pdf.vue';
import TitluPagina from '@/widgets/TitluPagina';
import Factura_pdf from '@/pages/facturi/Factura_pdf.vue';

export default {
    name: "devize",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Devize-dialog': Devize_dialog,
        'Deviz-produse-pdf': Deviz_produse_pdf,
        'titlu-pagina': TitluPagina,
        'factura-dlg': Factura_pdf,
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                masini: [],             },
            Filters: {
                DataIntrare: '' , DataInchidere: '' , Status: '-1' , IdMasina: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-devize')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("devize/get_info" );
            this.Info.masini = response.masini;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("devize/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('devize');
        },
        reset(){
            this.Filters = { DataIntrare: '' , DataInchidere: '' , Status: '-1' , IdMasina: '-1' };               
            this.refresh_info();
        },       
        async genereaza_factura(item){
          this.$confirm('Sunteti sigur ca doriti generarea facturii?', 'Atentionare', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Anulare',
            type: 'warning'
          }).then(async () => {
            var response = await this.post("facturi/generare_factura/", { id_deviz: item.Id } );
            var idFactura = response.IdFactura
            this.$refs.factura_pdf.genereaza(idFactura)
            this.refresh_info();
          }).catch(() => { 
            //fac nimic
          })
        },
        preview_factura (deviz) {
          this.$refs.factura_pdf.genereaza(deviz.IdFactura)
        },

        preview_produse(item){
            this.$refs['deviz-produse-pdf'].show(item.Id)
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
